import logo from './logo.svg';
import './App.css';
import Home from "./Home";

function App() {
    return (
        <div className="App">
            <div className={'AppBg'}/>
            <Home/>
        </div>
    );
}

export default App;
