import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCalendarDay, faLocation, faLocationArrow, faLocationDot} from '@fortawesome/free-solid-svg-icons'
import Lottie from 'react-lottie';
import heartData from './assets/animation/heart.json';
import Countdown from 'react-countdown';

const Completionist = () => <span>You are good to go!</span>;
const renderer = ({days, hours, minutes, seconds, completed}) => {
    if (completed) {
        // Render a completed state
        return <Completionist/>;
    } else {
        // Render a countdown
        return <span className={'countdown-group'}>
            <span>
                {days}
                <small>gün</small>
            </span>
            <span>
                {hours}
                <small>saat</small>
            </span>
            <span>
                {minutes}
                <small>dakika</small>
            </span>
            <span>
                {seconds}
                <small>saniye</small>
            </span>
        </span>;
    }
};

class Home extends React.Component {
    render() {
        const _date = Date.parse("2024-07-28 14:30+03:00");
        return <>
            <div className={'page-card'}>
                <div className="names">
                    <div className={'d-flex'}>
                        <h3 className={'name calligraphy-font'}>Ayşegül</h3>
                        <Lottie
                            options={{
                                loop: true,
                                autoplay: true,
                                animationData: heartData,
                                rendererSettings: {
                                    preserveAspectRatio: "xMidYMid slice"
                                }
                            }}
                            height={60}
                            width={90}
                        />
                        <h3 className={'name calligraphy-font'}>Fatih</h3>
                    </div>
                    <p className={'text-white sub-text font2_light'}>Nikah Töreni</p>
                </div>


                <div className="date font2_light">
                    <FontAwesomeIcon icon={faCalendarDay} style={{marginRight: 10}}/> 28/07/2024
                </div>
                <div className="countdown">
                    <Countdown
                        date={_date}
                        daysInHours={true}
                        renderer={renderer}
                    />
                </div>

                <div className={'location'}>
                    <div className={'font2_light'}>
                        <span className={'font2_semibold strong'}>Riva Balo</span>
                        <br/>
                        <span>ACITY Alışveriş Merkezi Üst Katı</span>
                        <p><FontAwesomeIcon icon={faLocationDot} style={{marginRight: 10}}/> Fatih Sultan Mehmet Bulvarı
                            No:244</p>
                        <a target={'_blank'} href={'https://www.google.com/maps/dir//Macun,+Fatih+Sultan+Mehmet+Blv+No:224,+06170+Yenimahalle%2FAnkara/@39.9468827,32.6801948,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x14d34eb1400fe59f:0x50deb3075150244!2m2!1d32.7625957!2d39.9469119?entry=ttu'} className="maps-button">
                            <div className="wrapper">
                                <p className="text">Konum <FontAwesomeIcon icon={faLocationArrow} /></p>

                                <div className="flower flower1">
                                    <div className="petal one"></div>
                                    <div className="petal two"></div>
                                    <div className="petal three"></div>
                                    <div className="petal four"></div>
                                </div>
                                <div className="flower flower2">
                                    <div className="petal one"></div>
                                    <div className="petal two"></div>
                                    <div className="petal three"></div>
                                    <div className="petal four"></div>
                                </div>
                                <div className="flower flower3">
                                    <div className="petal one"></div>
                                    <div className="petal two"></div>
                                    <div className="petal three"></div>
                                    <div className="petal four"></div>
                                </div>
                                <div className="flower flower4">
                                    <div className="petal one"></div>
                                    <div className="petal two"></div>
                                    <div className="petal three"></div>
                                    <div className="petal four"></div>
                                </div>
                                <div className="flower flower5">
                                    <div className="petal one"></div>
                                    <div className="petal two"></div>
                                    <div className="petal three"></div>
                                    <div className="petal four"></div>
                                </div>
                                <div className="flower flower6">
                                    <div className="petal one"></div>
                                    <div className="petal two"></div>
                                    <div className="petal three"></div>
                                    <div className="petal four"></div>
                                </div>
                            </div>
                        </a>

                    </div>
                </div>
            </div>
        </>
    }
}

export default Home
